<div class="header-container product-actions-container">
  <div>
    <p *ngIf="!shouldShowMarketplaceV3" class="content-secondary-color no-margin body2--medium">
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_READY_TO_SELL' | translate }}
    </p>
    <div class="full-height" *ngIf="!isDuplicateAccount; else isDuplicate">
      <section class="product-actions">
        <div class="product-availability-wrapper product-ordering-actions" *ngIf="!preOrderV3Flag">
          <p
            *ngIf="
              !userHasStockAvailability ||
              productAvailability === productAvailabilityEnum.available_for_preorder
            "
            class="product-availability"
          >
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.PRODUCT_STATUS' | translate }} :
            <span class="product-availability-text" [ngStyle]="{ color: availabilityTextColor }">{{
              productAvailabilityTranslationKey | translate
            }}</span>
          </p>
          <button
            *ngIf="bulkPreOrderButtonIsVisible"
            class="action-button bulk-preorder-button clickable body2--bold"
            (click)="onPreOrderRequestClick()"
          >
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.BULK_PRE_ORDER' | translate }}
          </button>
        </div>
        <section class="product-ordering-actions" *ngIf="!shouldShowMarketplaceV3; else newOrderUI">
          <button
            class="action-button add-to-cart-button clickable body2--bold"
            (click)="onAddToCart()"
            [disabled]="
              productAvailability === productAvailabilityEnum.not_available || !productIsOrderable
            "
          >
            <img class="add-to-cart-icon" src="assets/img/cart.svg" />
            {{ 'PRODUCTS_PAGE.ADD_TO_CART' | translate }}
          </button>
          <button
            class="action-button order-now-button clickable body2--bold"
            (click)="onOrderNow()"
            [disabled]="
              productAvailability === productAvailabilityEnum.not_available ||
              !productIsOrderable ||
              isMarketClosed
            "
            data-test-id="order-now-btn"
          >
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ORDER_NOW' | translate }}
          </button>
        </section>

        <ng-template #newOrderUI>
          <section class="product-ordering-actions order-options">
            <mat-radio-group [(ngModel)]="orderActionSelected" aria-label="Select an option">
              <mat-radio-button value="order-now">
                <p class="body2--bold content-main ta-start">
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.ORDER_TITLE') }}
                </p>
                <p class="caption1--regular">
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.ORDER_DESC') }}
                </p>
              </mat-radio-button>
              <ng-container *ngIf="orderActionSelected === 'order-now'">
                <div class="button-container">
                  <button
                    class="order-action-button cart-button body2--bold"
                    (click)="onAddToCart()"
                    [disabled]="
                      productAvailability === productAvailabilityEnum.not_available ||
                      !productIsOrderable
                    "
                  >
                    <img class="add-to-cart-icon" src="assets/img/cart-brand-color.svg" />
                    {{ 'PRODUCTS_PAGE.ADD_TO_CART' | translate }}
                  </button>
                  <button
                    class="order-action-button order-now-button clickable body2--bold"
                    (click)="onOrderNow()"
                    [disabled]="
                      productAvailability === productAvailabilityEnum.not_available ||
                      !productIsOrderable ||
                      isMarketClosed
                    "
                    data-test-id="order-now-btn"
                  >
                    {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ORDER_NOW' | translate }}
                  </button>
                </div>
              </ng-container>
              <mat-radio-button value="marketplace">
                <p class="body2--bold content-main ta-start">
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_TITLE') }}

                  <span class="new-status-tag caption2--medium">
                    {{ trans('CATALOG_PAGE.NEW_TAB') }}
                  </span>
                </p>
                <div class="tags">
                  <p class="gray-bg caption1--regular">
                    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_DAYS_TAG') }}
                  </p>
                  <p class="gray-bg caption1--regular">
                    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_HOURS_TAG') }}
                  </p>
                </div>
              </mat-radio-button>
              <ng-container *ngIf="orderActionSelected === 'marketplace'">
                <app-product-action-marketplace
                  [basicInfo]="basicInfo"
                ></app-product-action-marketplace>
              </ng-container>
              <mat-radio-button value="preorder" *ngIf="preOrderV3Flag && !isBundle">
                <p class="body2--bold content-main ta-start">
                  <img class="icon-style crown-img" src="assets/img/crown.svg" />
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_TITLE') }}
                </p>
                <div class="tags">
                  <p class="crown-bg caption1--regular">
                    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_TAG_1') }}
                  </p>
                  <p class="crown-bg caption1--regular">
                    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_TAG_2') }}
                  </p>
                  <p class="crown-bg caption1--regular">
                    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_TAG_3') }}
                  </p>
                </div>
              </mat-radio-button>
              <ng-container *ngIf="orderActionSelected === 'preorder'">
                <div class="action-container">
                  <div class="tags">
                    <p class="crown-bg body2--bold">
                      <img src="assets/img/crown.svg" />
                      {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_SUB_TITLE') }}
                    </p>
                  </div>
                  <p class="body2--bold content-main preorder-title">
                    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_MAIN') }}
                  </p>
                  <p class="caption2--regular ta-start">
                    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_SUB') }}
                  </p>
                  <button (click)="onPreOrderRequestClick()" class="preorder-button body2--regular">
                    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_MAIN_BUTTON') }}
                  </button>
                </div>
              </ng-container>
            </mat-radio-group>
          </section>
        </ng-template>

        <section class="favorite-and-share-section">
          <button
            class="add-to-catalog-button clickable body2--bold"
            (click)="onToggleIsCataloged()"
          >
            <img class="icon-style" *ngIf="!productIsCataloged" src="assets/img/heart.svg" />
            <img class="icon-style" *ngIf="productIsCataloged" src="assets/img/heart-active.svg" />
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ADD_TO_CATALOG' | translate }}
          </button>
        </section>
        <section
          class="preorder-v3-section"
          *ngIf="preOrderV3Flag && !isBundle && !shouldShowMarketplaceV3"
        >
          <img src="assets/img/locked.svg" alt="lock" />
          <div>
            <p class="body1--bold content-main">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.PREORDER.TITLE' | translate }}
            </p>
            <p class="body2--regular content-medium">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.PREORDER.DESCRIPTION' | translate }}
            </p>
          </div>
          <button (click)="onPreOrderRequestClick()" class="preorder-v3-section__CTA">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.PREORDER.RESERVE' | translate }}
          </button>
        </section>
      </section>
    </div>
  </div>
</div>
<ng-template #isDuplicate>
  <div class="duplicate-account">
    <img src="assets/img/product-details-icons/lock.svg" alt="" />
    {{ trans('wallet.duplicateAccountError') }}
  </div>
</ng-template>
