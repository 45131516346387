/* eslint-disable-next-line */
import { ProductLockRequestCreateModel } from '@/v3/features/product-lock-request/data/models';
import { ProductLockRequestUseCaseResolver } from '@/v3/features/product-lock-request/domain/resolvers/product-lock-request-use-case.resolver';
/* eslint-disable-next-line */
import { catchError } from '@/v3/presentation/shared/error';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { ProductBasicInfo } from 'app/core/domain/products/product-detail.model';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { OrderFulfilmentGuageComponent } from '../order-fulfilment-gauge/order-fulfilment-guage.component';
import { ProductFulfilmentDetailsPopupComponent } from '../product-fulfilment-details-popup/product-fulfilment-details-popup.component';
import { ProductLockRequestSuccessPopupComponent } from '../product-lock-request-success-popup/product-lock-request-success-popup.component';

@Component({
  selector: 'app-product-action-marketplace',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatSelectModule, OrderFulfilmentGuageComponent],
  templateUrl: './product-action-marketplace.component.html',
  styleUrls: ['./product-action-marketplace.component.scss'],
})
export class ProductActionMarketplaceComponent extends LocalizedComponent implements OnInit {
  constructor(private _formBuilder: FormBuilder, private _matDialog: MatDialog) {
    super();
  }

  @Input() basicInfo: ProductBasicInfo;

  public marketplaceForm: FormGroup;

  public reasonlist = [
    {
      name: 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_TESTING_VALUE',
      value: 'PRODUCT_TESTING',
    },
    {
      name: 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_SCALING_VALUE',
      value: 'PRODUCT_SCALING',
    },
    { name: 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_OTHER_VALUE', value: 'OTHER' },
  ];

  ngOnInit(): void {
    this._initializeForm();
  }

  private _initializeForm(): void {
    this.marketplaceForm = this._formBuilder.group({
      expectedDailyOrders: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      reason: ['', Validators.required],
    });
  }

  public onViewFulfilmentDetails(): void {
    this._matDialog.open(ProductFulfilmentDetailsPopupComponent, {
      width: '536px',
      direction: this.isLTR ? 'ltr' : 'rtl',
    });
  }

  onSubmit(): void {
    if (this.marketplaceForm.valid) {
      const formData = this.marketplaceForm.value;
      const data: ProductLockRequestCreateModel = {
        sku: this.basicInfo.productId,
        requestReason: formData.reason,
        expectedDailyOrders: +formData.expectedDailyOrders,
      };
      ProductLockRequestUseCaseResolver.productLockRequestCreate(data)
        .then((result) => {
          this._matDialog.open(ProductLockRequestSuccessPopupComponent, {
            width: '536px',
            direction: this.isLTR ? 'ltr' : 'rtl',
          });
        })
        .catch((error) => {
          catchError(error);
        });
    }
  }
}
