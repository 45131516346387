/* eslint-disable-next-line */
import { user } from '@/v3/features/user/data/services/user';
import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { country } from '@features/country/data';
import { TranslateModule } from '@ngx-translate/core';
import { getMerchantIdVerification } from '@presentation/kyc/atoms/utils/get-verification-object';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { featureAttributeAssign } from 'app/presentation/shared/utilities/feature-attribute-assign.utility';
import {
  ProductAvailability,
  ProductBasicInfo,
} from 'src/app/core/domain/products/product-detail.model';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { IsFeatureEnabledUseCase } from 'src/app/core/usecases/market-availability/is-feature-enabled.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { PRE_ORDER_USER_FEATURE } from 'src/app/presentation/shared/constants';
import {
  MARKETPLACE_USER_FEATURE,
  PREORDER_V3,
  WEB_MARKETPLACE_V3,
} from 'src/app/presentation/shared/constants/feature-flags';
import { ProductActionMarketplaceComponent } from './product-action-marketplace/product-action-marketplace.component';

@Component({
  selector: 'app-product-actions',
  templateUrl: './product-actions.component.html',
  styleUrls: ['./product-actions.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgFor,
    NgStyle,
    TranslateModule,
    AsyncPipe,
    MatRadioModule,
    FormsModule,
    ProductActionMarketplaceComponent,
  ],
})
export class ProductActionsComponent extends LocalizedComponent implements OnInit, OnChanges {
  @Input() basicInfo: ProductBasicInfo;

  @Input() productAvailability: ProductAvailability;

  @Input() isMarketplaceEnabled: boolean;

  @Input() productIsCataloged: boolean;

  @Input() bulkPreOrderButtonIsVisible: boolean;

  @Input() productIsOrderable: boolean;

  @Input() userHasStockAvailability: boolean;

  @Input() isDiscountOnQuantityEnabled: boolean;

  @Input() isBundle: boolean;

  @Output() addToCart: EventEmitter<void> = new EventEmitter();

  @Output() orderNow: EventEmitter<number> = new EventEmitter();

  @Output() openBulkRequestDialog: EventEmitter<void> = new EventEmitter();

  @Output() toggleIsCataloged: EventEmitter<void> = new EventEmitter();

  productAvailabilityTranslationKey: string;

  availabilityTextColor: string;

  productAvailabilityEnum = ProductAvailability;

  preOrderV3Flag = false;

  currentCountry: string;

  isMarketClosed = false;

  isDuplicateAccount = false;

  shouldShowMarketplaceV3 = false;

  orderActionSelected: 'order-now' | 'marketplace' | 'preorder' = 'order-now';

  constructor(
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    private _isFeatureEnabledUseCase: IsFeatureEnabledUseCase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkMerchantIdVerification();

    if (!this.isMarketplaceEnabled && this.isBundle) {
      this.isBundle = false;
    }
    this._isFeatureEnabledUseCase.execute('orders').subscribe((res) => {
      this.isMarketClosed = !res;
    });
    this.enableMarketplaceV3();
  }

  ngOnChanges(): void {
    this.mapProductAvailability();
  }

  protected checkMerchantIdVerification(): void {
    getMerchantIdVerification(
      this._getFeatureFlagUseCase,
      this._checkUserFeatureExistsUseCase,
    ).then((response) => {
      this.isDuplicateAccount = response.isDuplicate;
      if (!this.isDuplicateAccount) {
        this.checkPreOrderFeature();
      }
    });
  }

  checkPreOrderFeature(): void {
    this._getFeatureFlagUseCase.execute(PREORDER_V3).subscribe((flag) => {
      const selectedCountry = country.code;
      this.preOrderV3Flag =
        flag &&
        this._checkUserFeatureExistsUseCase.execute(
          PRE_ORDER_USER_FEATURE + selectedCountry.toLowerCase(),
        );
    });
  }

  public enableMarketplaceV3(): void {
    this._getFeatureAttributeUseCase.execute(WEB_MARKETPLACE_V3).subscribe({
      next: (attribute) => {
        this.shouldShowMarketplaceV3 =
          featureAttributeAssign(attribute, user.id, country.code) &&
          user.features.includes(MARKETPLACE_USER_FEATURE);
      },
    });
  }

  mapProductAvailability(): void {
    switch (this.productAvailability) {
      case ProductAvailability.available:
        this.productAvailabilityTranslationKey =
          'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_AVAILABLE';
        this.availabilityTextColor = '#3dbb54';
        break;
      case ProductAvailability.available_for_preorder:
        this.productAvailabilityTranslationKey =
          'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_AVAILABLE_FOR_PRE_ORDER';
        this.availabilityTextColor = '#3dbb54';
        break;
      case ProductAvailability.not_available:
        this.productAvailabilityTranslationKey =
          'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_NOT_AVAILABLE';
        this.availabilityTextColor = '#ff4966';
        break;
      case ProductAvailability.available_with_high_qty:
        this.productAvailabilityTranslationKey =
          'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_AVAILABLE_WITH_HIGH_QUANTITY';
        this.availabilityTextColor = '#3dbb54';
        break;
      case ProductAvailability.available_with_low_qty:
        this.productAvailabilityTranslationKey =
          'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_AVAILABLE_WITH_LOW_QUANTITY';
        this.availabilityTextColor = '#ffae0c';
        break;
      default:
        this.productAvailabilityTranslationKey = '';
        this.availabilityTextColor = '#3dbb54';
        break;
    }
  }

  onAddToCart(): void {
    this.addToCart.emit();
  }

  onPreOrderRequestClick(): void {
    this.openBulkRequestDialog.emit();
  }

  onOrderNow(): void {
    this.orderNow.emit();
  }

  onToggleIsCataloged(): void {
    this.toggleIsCataloged.emit();
  }
}
