import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { OrderFulfilmentGuageComponent } from '../order-fulfilment-gauge/order-fulfilment-guage.component';

@Component({
  selector: 'app-product-fulfilment-details-popup',
  standalone: true,
  imports: [CommonModule, OrderFulfilmentGuageComponent],
  templateUrl: './product-fulfilment-details-popup.component.html',
  styleUrls: ['./product-fulfilment-details-popup.component.scss'],
})
export class ProductFulfilmentDetailsPopupComponent extends LocalizedComponent {
  constructor(public _dialogRef: MatDialogRef<ProductFulfilmentDetailsPopupComponent>) {
    super();
  }

  public closeDialog(): void {
    this._dialogRef.close();
  }
}
