<div class="success-container">
  <p class="heading2--bold title">
    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_SUCCESS_TITLE') }}
  </p>
  <p class="body2--regular">
    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_SUCCESS_SUB') }}
  </p>
  <div class="btn-container body2--regular">
    <button class="action-btn cancel-btn" (click)="closeDialog()">
      {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_SUCCESS_IGNORE') }}
    </button>
    <button class="action-btn track-btn" (click)="redirectToRequests()">
      {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_SUCCESS_TRACK') }}
    </button>
  </div>
</div>
