<div class="details-container">
  <p class="heading2--bold title">
    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.FULFILMENT_RATE_TITLE') }}
  </p>
  <app-order-fulfilment-guage></app-order-fulfilment-guage>
  <div class="mt-4 ta-start">
    <div class="faq">
      <p class="body2--bold">
        {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.FULFILMENT_RATE_Q1') }}
      </p>
      <p class="body2--regular">
        {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.FULFILMENT_RATE_A1') }}
      </p>
    </div>
    <div class="faq">
      <p class="body2--bold">
        {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.FULFILMENT_RATE_Q2') }}
      </p>
      <ul>
        <li>
          {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.FULFILMENT_RATE_A2-1') }}
        </li>
        <li>
          {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.FULFILMENT_RATE_A2-2') }}
        </li>
      </ul>
    </div>
    <button class="body2--regular primary-btn" (click)="closeDialog()">
      {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.FULFILMENT_RATE_BUTTON') }}
    </button>
  </div>
</div>
