import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
/* eslint-disable-next-line */
import { navigateTo } from '@/v3/presentation/shared/router/navigation';
import { CATALOG_URL } from 'app/presentation/shared/constants/app-urls';

@Component({
  selector: 'app-product-lock-request-success-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './product-lock-request-success-popup.component.html',
  styleUrls: ['./product-lock-request-success-popup.component.scss'],
})
export class ProductLockRequestSuccessPopupComponent extends LocalizedComponent {
  constructor(public _dialogRef: MatDialogRef<ProductLockRequestSuccessPopupComponent>) {
    super();
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

  redirectToRequests(): void {
    this.closeDialog();
    navigateTo(CATALOG_URL, { showLockedRequests: true });
  }
}
